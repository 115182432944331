import React from 'react'
import {navigate} from 'gatsby'
import {handleLogin, isLoggedIn} from './services/auth'
import {FaFacebookF} from 'react-icons/fa/'
import {FaGooglePlusG} from 'react-icons/fa/'
import {FaLinkedinIn} from 'react-icons/fa/'
import {  } from "typeface-montserrat"
//import 'https://fonts.googleapis.com/css?family=Montserrat:400,800'

class Login extends React.Component {
  state = {
    isSignUp: false
  }
  handleSubmit = () => handleLogin(user => navigate(`/app/profile`))
  render() {
  

    console.log('START')
    /**   
     * 
    fetch("http://localhost:9000/hello", {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }

    })*/
    fetch("/.netlify/functions/hello", {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }

    })
    .then(response=>response.json())
    .then(console.log)
    console.log('END')
  /*.then(response => response.json())
  .then(console.log)*/
    return (
      <div className="reset">
      <div className="container" id="container">
      <div className="form-container sign-up-container">
        <form action="#">
          <h1>Create Account</h1>
          <div className="social-container">
            <a href="#" className="social"><FaFacebookF /></a>
            <a href="#" className="social"><FaGooglePlusG /></a>
            <a href="#" className="social"><FaLinkedinIn /></a>
          </div>
          <span>or use your email for registration</span>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Password" />
          <button>Sign Up</button>
        </form>
      </div>
      <div className="form-container sign-in-container">
        <form action="#">
          <h1>Sign in</h1>
          <div className="social-container">
            <a href="#" className="social"><FaFacebookF /></a>
            <a href="#" className="social"><FaGooglePlusG /></a>
            <a href="#" className="social"><FaLinkedinIn /></a>
          </div>
          <span>or use your account</span>
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Email" />
          <a href="#">Forgot your password?</a>
          <button>Sign In</button>
        </form>
      </div>
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-left">
            <h1>Welcome Back!</h1>
            <p>
              To keep connected with us please login with your personal info
            </p>
            <button className="ghost" id="signIn">Sign In</button>
          </div>
          <div className="overlay-panel overlay-right">
            <h1>Hello, Friend!</h1>
            <p>Enter your personal details and start journey with us</p>
            <button className="ghost" id="signUp">Sign Up</button>
          </div>
        </div>
      </div>
    </div>
    {/* --- STYLES --- */}
    <style jsx>{`
        * {
          box-sizing: border-box;
        }
        
        body {
          font-family: 'Montserrat', sans-serif;
          background: #f6f5f7;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100vh;
          margin: -20px 0 50px;
        }
        
        h1 {
          font-weight: bold;
          margin: 0;
        }
        
        p {
          font-size: 14px;
          font-weight: 100;
          line-height: 20px;
          letter-spacing: 0.5px;
          margin: 20px 0 30px;
        }
        
        span {
          font-size: 12px;
        }
        
        a {
          color: #333;
          font-size: 14px;
          text-decoration: none;
          margin: 15px 0;
        }
        
        .container {
          background: #fff;
          border-radius: 10px;
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          position: relative;
          overflow: hidden;
          width: 768px;
          max-width: 100%;
          min-height: 480px;
        }
        
        .form-container form {
          background: #fff;
          display: flex;
          flex-direction: column;
          padding: 0 50px;
          height: 100%;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        
        .social-container {
          margin: 20px 0;
        }
        
        .social-container a {
          border: 1px solid #ddd;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin: 0 5px;
          height: 40px;
          width: 40px;
        }
        
        .form-container input {
          background: #eee;
          border: none;
          padding: 12px 15px;
          margin: 8px 0;
          width: 100%;
        }
        
        button {
          border-radius: 20px;
          border: 1px solid #ff4b2b;
          background: #ff4b2b;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          padding: 12px 45px;
          letter-spacing: 1px;
          text-transform: uppercase;
          transition: transform 80ms ease-in;
        }
        
        button:active {
          transform: scale(0.95);
        }
        
        button:focus {
          outline: none;
        }
        button.ghost {
          background: transparent;
          border-color: #fff;
        }
        .form-container {
          position: absolute;
          top: 0;
          height: 100%;
          transition: all 0.6s ease-in-out;
        }
        
        .sign-in-container {
          left: 0;
          width: 50%;
          z-index: 2;
        }
        
        .sign-up-container {
          left: 0;
          width: 50%;
          opacity: 0;
          z-index: 1;
        }
        
        .overlay-container {
          position: absolute;
          top: 0;
          left: 50%;
          width: 50%;
          height: 100%;
          overflow: hidden;
          transition: transform 0.6s ease-in-out;
          z-index: 0;
        }
        
        .overlay {
          background: #ff416c;
          background: linear-gradient(to right, #ff4b2b, #ff416c) no-repeat 0 0 / cover;
          color: #fff;
          position: relative;
          left: -100%;
          height: 100%;
          width: 200%;
          transform: translateX(0);
          transition: transform 0.6s ease-in-out;
        }
        
        .overlay-panel {
          position: absolute;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 40px;
          height: 100%;
          width: 50%;
          text-align: center;
          transform: translateX(0);
          transition: transform 0.6s ease-in-out;
        }
        
        .overlay-right {
          right: 0;
          transform: translateX(0%);
        }
        
        .overlay-left {
          transform: translateX(-20%);
        }
        
        /* Animation */
        
        /* Move signin to the right */
        .container.right-panel-active .sign-in-container {
          transform: translateX(100%);
        }
        
        /* Move overlay to the left */
        .container.right-panel-active .overlay-container {
          transform: translateX(-100%);
        }
        
        /* Bring sign up over sign in */
        .container.right-panel-active .sign-up-container {
          transform: translateX(100%);
          opacity: 1;
          z-index: 5;
        }
        
        /* Move overlay back to right */
        .container.right-panel-active .overlay {
          transform: translateX(50%);
        }
        
        .container.right-panel-active .overlay-left {
          transform: translateX(0);
        }
        
        .container.right-panel-active .overlay-right {
          transform: translateX(20%);
        }
        
        
      `}</style>
    </div>
    )
  }
}

export default Login
